<template>
  <div>
    <div v-if="isLoading" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="main-content" :class="{ 'is-hidden': isLoading }">
      <div class="section padding">
        <div class="container spacing_zero less-padding w-container">
          <div class="content-header-spread"></div>
          <div class="horizontal-jobs-wrapper">
            <div class="w-dyn-list">
              <div role="list" class="horizontal-jobs-grid w-dyn-items">
                <div
                  v-for="job in filteredJobsByCompany"
                  :key="job.id"
                  class="job-card-item w-dyn-item job-card horizontal"
                  :data-job-id="job.id"
                >
                  <a
                    :href="'https://jobs.adan.careers/jobs/' + job.id"
                    class="job-card-item w-dyn-item job-card-routerLink horizontal"
                    target="_top"
                  >
                  <div class="job-card-company-wrap square w-inline-block">
                    <div class="company-large-icon-wrap">
                      <img
                        :src="job.properties.companyImageLink.url"
                        alt="Company Logo"
                        class="company-icon-job"
                      />
                    </div>
                    </div>
                  
                  <div class="job-card-content horizontal">
                    <div>
                      <a
                        :href="job.jobLink"
                        class="job-card-link w-inline-block"
                      >
                        <h3 class="heading-11">
                          {{ job.properties.job_title.title[0].text.content }}
                        </h3>
                      </a>
                      <div class="text-block-26">{{ job.description }}</div>
                    </div>
                    <div class="job-tags-wrap">
                      <a :href="job.employmentTypeLink" class="job-tag">{{
                        job.properties.employment_type.select.name
                      }}</a>
                      <a :href="job.levelLink" class="job-tag">{{
                        job.properties.level.select.name
                      }}</a>
                      <a :href="job.locationLink" class="job-tag">{{
                        job.properties.location.rich_text[0].text.content
                      }}</a>
                    </div>
                    <div class="job-card-head">
                      <a :href="job.categoryLink" class="link">{{
                        job.properties.category.select.name
                      }}</a>
                      <div class="opacity-black">
                        {{
                          new Date(job.properties.created_time.created_time)
                            .toISOString()
                            .slice(0, 10)
                        }}
                      </div>
                    </div>
                   
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  name: "JobList",
  setup() {
    const jobs = ref([]);
    const isLoading = ref(false);
    const route = useRoute();
    const companyName = ref(route.query.company || ""); // Nimmt den Unternehmensnamen aus der URL

    const fetchJobs = async () => {
      isLoading.value = true;
      try {
        const response = await axios.get(
          "https://adan-secureserver.mainly-dev.de:3000/jobs"
        );
        jobs.value = response.data;
      } catch (error) {
        console.error("Error fetching job data: ", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(fetchJobs);

    const filteredJobsByCompany = computed(() => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      return jobs.value.filter((job) => {
        const onlineUntil = new Date(job.properties.onlineUntil.date.start);
        const isFutureDate = onlineUntil >= currentDate;
        return (
          //isFutureDate && matchesLocation && matchesCategory && matchesLevel &&isPartnerPortal
          isFutureDate && job.properties.company.rich_text[0].text.content === companyName.value
        );
      });
    });

    return {
      isLoading,
      companyName,
      filteredJobsByCompany,
    };
  },
};
</script>

<style scoped>
.filters {
  display: flex;
  flex-wrap: wrap; /* Erlaubt das Umbruch der Elemente */
  gap: 10px; /* Abstand zwischen den Filter-Elementen */
  justify-content: center; /* Zentriert die Filter, wenn weniger Platz vorhanden ist */
}

.filter-dropdown {
  padding: 5px; /* Fügt Padding innerhalb des Dropdowns für bessere Lesbarkeit hinzu */
  min-width: 120px; /* Mindestbreite für jedes Dropdown, um sicherzustellen, dass Inhalte sichtbar sind */
  flex: 1 1 auto; /* Ermöglicht den Dropdowns, sich innerhalb des verfügbaren Raums auszudehnen */
}

@media (max-width: 600px) {
  /* Stiländerungen für Bildschirme kleiner als 600px */
  .filters {
    flex-direction: column; /* Stapelt die Filter-Elemente untereinander */
  }

  .filter-dropdown {
    width: 100%; /* Lässt die Dropdowns die volle Breite einnehmen */
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* oder 'absolute', abhängig vom Kontext */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* leicht transparenter Hintergrund */
  z-index: 10; /* Stellt sicher, dass der Spinner über anderen Inhalten liegt */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #a4d7d4;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-hidden {
  display: none;
}
</style>
