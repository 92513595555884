import { createRouter, createWebHistory } from 'vue-router';
import JobsAll from '../components/JobsAll.vue'; 

const routes = [
  { path: '/', component: JobsAll }, // Stelle sicher, dass dies vorhanden ist
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
